<template>
    <v-file-input accept="image/*" label="" required outlined dense append-icon="mdi-paperclip" prepend-icon=""
        @change="handleImage($event)" :placeholder="item.photo_name"></v-file-input>
</template>


<script>
import { mapState, mapActions } from "vuex";
export default {
    name: 'InputPhotoUpload',
    props: ['item','parentCallback'],
    computed: {
    },
    methods: {
        ...mapActions(["uploadImage"]),
        handleImage(e) {
            if (e) {
                const formData = new FormData();
                formData.append("image", e);
                this.uploadImage({ data: formData })
                    .then((data) => {
                        const { file_url, file } = data;
                        const { originalname } = file;
                        this.item = Object.assign(this.item, {
                            photo_url: file_url,
                            photo_name: originalname
                        });

                        if(this.parentCallback){
                            this.parentCallback(this.item);
                        }

                    })
                    .catch((err) => {
                        this.mensaje(true, "error", err.errorMessage, 5000);
                    });
            }
        },

    }

}

</script>


<style>
</style>