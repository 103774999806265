<template>
    <v-container fluid>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Puesto</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-select v-model="award.award_position" :items="award_positions" item-value="id"
                                        item-text="name" label="" outlined dense />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Nombre</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="award.award_name" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
       
        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Foto</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <InputPhotoUpload :item="award" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import mixins from "@/mixins";
import InputPhotoUpload from "../util/InputPhotoUpload.vue";

export default {
    name: "form-award",
    props: ["award"],
    components: { InputPhotoUpload },
    watch: {},
    computed: {
        ...mapState({
            award_positions: (state) => state.util.award_positions,
        }),
    },
    validations() {
        return {
            award: {
                // title: required,
                // fifa_position: required,
                // continent_id: required,
                // games_won: required,
                // games_lost: required,
                // games_tied: required,
                // goals: required,
                // players: required,
            },
        };
    },
    data: () => ({
    }),
    methods: {
        ...mapActions(["fetchAwardPositions"]),
        getFetch() {
            this.fetchAwardPositions();
        },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style scoped>
/* .row {
    margin-top: -10px !important;
} */
</style>