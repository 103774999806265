<template>
    <v-container fluid>
        <v-col col="12" md="12" lg="12" outlined>
            <v-card class="pa-4 rounded-xl">
                <v-card-title class="justify-left d-flex flex-row pb-1">
                    <p>Premio</p>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-divider color="#75D89F" class="mx-3"></v-divider>
                <v-card-text>
                    <!-- <h3 style="color:#083C5F" class="pb-4">Información del Grupo</h3> -->
                    <v-row>
                        <v-col col="12">
                            <form-award :award="iaward"></form-award>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-end">
                        <v-btn @click="save()" class="ma-3 rounded-lg text-capitalize" dense color="primary">
                            Grabar
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
            <span class="d-flex justify-center white--text">{{ text }}</span>
        </v-snackbar>
    </v-container>
</template>
  
  
  
  <script>
import FormAward from "@/components/forms/FormAward.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import _cloneDeep from 'lodash/cloneDeep';

export default {
    name: "AwardAddEdit",
    props: ["id"],
    components: {
        FormAward,
    },
    watch: {
        award: function (value) {
            // console.log(value)
            if (this.id) {
                this.iaward = _cloneDeep(value);
            }
        },
        awardError: function (value) {
            if (value)
                this.mensaje(true, "error", this.awardError.message, 5000);
        },
        awardSusccesMessage: function (value) {
            if (value) {
                this.mensaje(true, "success", this.awardSusccesMessage, 5000);
                if (!this.id) this.iaward = {};
                this.$router.go(-1);
            }
        },

    },
    computed: {
        ...mapState({
            award: (state) => state.award.award,
            awardError: (state) => state.award.awardError,
            awardSusccesMessage: (state) => state.award.awardSusccesMessage,
        }),
        ...mapGetters(["userSession"]),
    },
    data: () => ({
        iaward: {
        },
        snackbar: false,
        color: "",
        text: "",
    }),
    methods: {
        ...mapActions(["addAward", "editAward", "getAwardById"]),
        save() {
            // console.log(this.iaward);
            if (this.id) {
                //update
                this.editAward({ award: this.iaward });
            } else {
                this.addAward({
                    award: {
                        ...this.iaward,
                        polla_id: this.userSession.polla_id,
                        award_type: 2
                    }
                });
                //create
            }
        },
        mensaje(snackbar, color, text) {
            this.snackbar = snackbar;
            this.color = color;
            this.text = text;
        },
        getFetch() {
            // console.log(this.award)
            if (this.id) {
                this.getAwardById({ id: this.id });
            }
        },
    },
    created() {
        this.getFetch();
    },
};
</script>
  
  <style scoped>
  </style>